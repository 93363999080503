import { Box, Flex, Spinner } from '@chakra-ui/react';
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LoadingFallback from '@/components/LoadingFallback';
import UnAuthenticationRoute from '@/components/Route/UnAuththenticateRoute';
import AuthenticationRoute from '@/components/Route/AuthenticationRoute';
import { userState } from '@/store/atoms/users';
import currentUserPreferences from '@/store/atoms/selectors/currentUserPreferences';
import {
  APP_ID_KEY,
  INVITATION_TOKEN_KEY,
  MAP_ID_KEY,
  MAP_SHARE_TOKEN_KEY,
  RESET_PASSWORD_TOKEN_KEY,
  TEAM_ID_KEY,
  USER_ID_KEY,
  VERIFICATION_TOKEN_KEY,
} from '@/routes/path';

import useFetchUserTeams from '@/hooks/fetcher/use-fetch-user-teams';
import MapManagementPage from './DeveloperConsolePage/MapManagementPage';

const DeveloperConsoleBase = React.lazy(
  () => import('@/templates/DeveloperConsole/DeveloperConsoleBase'),
);
const SidebarConsole = React.lazy(() => import('@/templates/DeveloperConsole/SidebarConsole'));
const WithoutSidebarConsole = React.lazy(
  () => import('@/templates/DeveloperConsole/WithoutSidebarConsole'),
);
const PlanSchedulesPage = React.lazy(() => import('./DeveloperConsolePage/PlanSchedules'));

const Dashboard = React.lazy(() => import('./DeveloperConsolePage/Dashboard'));
const ApplicationDetailPage = React.lazy(
  () => import('./DeveloperConsolePage/Dashboard/ApplicationDetailPage'),
);
const ApplicationEditPage = React.lazy(
  () => import('./DeveloperConsolePage/Dashboard/ApplicationEditPage'),
);
const MapClonePage = React.lazy(() => import('./DeveloperConsolePage/Dashboard/MapClonePage'));
const MapManagePage = React.lazy(() => import('./DeveloperConsolePage/Dashboard/MapManagePage'));
const DeveloperSettingPage = React.lazy(
  () => import('./DeveloperConsolePage/DeveloperSettingPage'),
);
const AccountDetailPage = React.lazy(
  () => import('./DeveloperConsolePage/DeveloperSettingPage/AccountDetailPage'),
);
const AccountSecurityChangePasswordPage = React.lazy(
  () => import('./DeveloperConsolePage/DeveloperSettingPage/AccountSecurityChangePassword'),
);
const AccountSecurityMFAPage = React.lazy(
  () => import('./DeveloperConsolePage/DeveloperSettingPage/AccountSecurityMFA'),
);
const DownloadPage = React.lazy(() => import('./DeveloperConsolePage/Download/DownloadPage'));
const KeyManagementPage = React.lazy(() => import('./DeveloperConsolePage/KeyManagement'));
const MapPage = React.lazy(() => import('./DeveloperConsolePage/MapPage'));
const OrganizationPendingPage = React.lazy(
  () => import('./DeveloperConsolePage/Organization/OrganizationPendingPage'),
);
const SettingPage = React.lazy(() => import('./DeveloperConsolePage/Setting'));
const ManageUsers = React.lazy(() => import('./DeveloperConsolePage/Setting/ManageUsers'));
const EditUser = React.lazy(() => import('./DeveloperConsolePage/Setting/ManageUsers/EditUser'));
const InviteUser = React.lazy(
  () => import('./DeveloperConsolePage/Setting/ManageUsers/InviteUser'),
);
const CreateTeam = React.lazy(() => import('./DeveloperConsolePage/Team'));
const EmailVerificationPage = React.lazy(() => import('./EmailVerificationPage'));
const FeedbackPage = React.lazy(() => import('./Feedback'));
const ForgotPasswordPage = React.lazy(() => import('./ForgotPasswordPage'));
const InvitePage = React.lazy(() => import('./InvitePage'));
const InviteeRegisterPage = React.lazy(() => import('./InvitePage/InviteeRegisterPage'));
const LogoutPage = React.lazy(() => import('./LogoutPage'));
const PageNotFound = React.lazy(() => import('./PageNotFound'));
const RegisterSuccessPage = React.lazy(() => import('./RegisterSuccessPage'));
const ResetPasswordPage = React.lazy(() => import('./ResetPasswordPage'));
const TermsPage = React.lazy(() => import('./Terms'));
const PlanPage = React.lazy(() => import('./DeveloperConsolePage/Setting/Plan'));
const CheckoutPage = React.lazy(() => import('./DeveloperConsolePage/Pricing/Checkout'));
const TeamOrdersPage = React.lazy(() => import('./DeveloperConsolePage/TeamOrders'));
const LandingPage = React.lazy(() => import('./LandingPage'));
const LoginPage = React.lazy(() => import('./LoginPage'));
const RegisterPage = React.lazy(() => import('./RegisterPage'));

const AppRoutes: React.FC = () => {
  const { data: orgs, isLoading, mutate } = useFetchUserTeams();

  const { token } = useRecoilValue(userState);
  const preference = useRecoilValue(currentUserPreferences);

  const activatedTeams = orgs?.filter((o) => o.activated);

  let preferredTeam = '/console/team/create';

  if (!isLoading && activatedTeams && activatedTeams.length > 0) {
    preferredTeam = preference.defaultTeamId
      ? `/console/team/${preference.defaultTeamId}`
      : `/console/team/${activatedTeams?.at(-1)?.id}`;
  }

  React.useEffect(() => {
    mutate();
  }, [token]);

  if (isLoading) {
    return (
      <Flex
        align="center"
        bg="white"
        bottom="0"
        h="100vh"
        justify="center"
        position="absolute"
        top="0"
        w="full"
        zIndex="10">
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box>
      <Routes>
        <Route path="console">
          <Route path="terms">
            <Route element={<TermsPage />} path={`:${INVITATION_TOKEN_KEY}`} />
            <Route index element={<TermsPage />} />
          </Route>

          <Route element={<InvitePage />} path={`invite/:${INVITATION_TOKEN_KEY}`} />
          <Route element={<UnAuthenticationRoute />}>
            <Route path="register">
              <Route element={<RegisterSuccessPage />} path="success" />
              <Route element={<EmailVerificationPage />} path={`:${VERIFICATION_TOKEN_KEY}`} />
              <Route index element={<RegisterPage />} />
            </Route>
            <Route path="invite">
              <Route
                element={<InviteeRegisterPage />}
                path={`new-account/:${INVITATION_TOKEN_KEY}`}
              />
            </Route>
            <Route path="login">
              <Route element={<LoginPage />} path={`:${INVITATION_TOKEN_KEY}`} />
              <Route index element={<LoginPage />} />
            </Route>
            <Route element={<ForgotPasswordPage />} path="forgot-password" />
            <Route
              element={<ResetPasswordPage />}
              path={`reset-password/:${RESET_PASSWORD_TOKEN_KEY}`}
            />
          </Route>

          <Route element={<LogoutPage />} path="logout" />
          <Route element={<AuthenticationRoute />}>
            <Route element={<DeveloperConsoleBase />}>
              <Route path="team">
                <Route element={<CreateTeam />} path="create" />
                <Route element={<SidebarConsole />} path={`:${TEAM_ID_KEY}`}>
                  <Route element={<OrganizationPendingPage />} path="pending" />
                  <Route path="app">
                    <Route element={<ApplicationEditPage />} path={`:${APP_ID_KEY}/edit`} />
                    <Route element={<ApplicationDetailPage />} path={`:${APP_ID_KEY}`} />
                    <Route index element={<Dashboard />} />
                  </Route>
                  <Route path="map">
                    <Route element={<MapPage />} path={`data/:${MAP_ID_KEY}`} />
                    <Route index element={<MapManagementPage />} />
                  </Route>
                  <Route path="keys">
                    <Route index element={<KeyManagementPage />} />
                  </Route>
                  <Route path="subscriptions">
                    <Route index element={<PlanPage />} />
                  </Route>
                  {/* <Route path="metrics">
                    <Route index element={<MetricsPage />} />
                  </Route> */}
                  <Route path="plan/requests">
                    <Route index element={<PlanSchedulesPage />} />
                  </Route>
                  <Route path="orders">
                    <Route index element={<TeamOrdersPage />} />
                  </Route>
                  <Route path={'checkout'}>
                    <Route index element={<CheckoutPage />} />
                  </Route>
                  <Route path="settings">
                    <Route path="users">
                      <Route element={<EditUser />} path={`:${USER_ID_KEY}`} />
                      <Route element={<InviteUser />} path="invite" />
                      <Route index element={<ManageUsers />} />
                    </Route>

                    <Route index element={<SettingPage />} />
                  </Route>

                  <Route index element={<Navigate replace={true} to="app" />} />
                </Route>
                <Route index element={<Navigate replace={true} to={preferredTeam} />} />
              </Route>

              <Route element={<WithoutSidebarConsole />} path="-">
                <Route path="clone">
                  <Route element={<MapClonePage />} path={`map/:${MAP_SHARE_TOKEN_KEY}`} />
                </Route>
                <Route path="account">
                  <Route index element={<DeveloperSettingPage />} />
                  <Route element={<AccountDetailPage />} path="info" />
                  <Route element={<AccountSecurityChangePasswordPage />} path="password" />
                  <Route element={<AccountSecurityMFAPage />} path="multi-factor-authentication" />
                </Route>
                <Route element={<DownloadPage />} path="download" />
                <Route element={<FeedbackPage />} path="feedback" />
              </Route>
              <Route index element={<Navigate replace={true} to={preferredTeam} />} />
            </Route>
          </Route>
          <Route index element={<Navigate replace={true} to={preferredTeam} />} />
        </Route>
        <Route
          element={
            <LoadingFallback>
              <LandingPage />
            </LoadingFallback>
          }
          path="home"
        />
        <Route element={<Navigate replace={true} to="home" />} path="/" />
        <Route element={<PageNotFound />} path="404" />
        <Route element={<Navigate replace={true} to="404" />} path="*" />
      </Routes>
    </Box>
  );
};

export default AppRoutes;
